import {
  ApolloCache,
  NormalizedCacheObject,
  useMutation,
} from '@apollo/client';
import { gql } from '@/__generated__';
import { useMe } from '../../routes/_app/use-me';

const Follow = gql(`
  mutation Follow($userId: ID!) {
    follow(userId: $userId)
  }
`);

const Unfollow = gql(`
  mutation Unfollow($userId: ID!) {
    unfollow(userId: $userId)
  }
`);

export const UserFollowingFragment = gql(`
fragment UserFollowingFragment on User {
  following
}`);

const writeUserFollowingFragment = (
  cache: ApolloCache<NormalizedCacheObject>,
  userId: string,
  following: boolean,
) => {
  cache.writeFragment({
    id: cache.identify({
      __typename: 'User',
      id: userId,
    }),
    fragment: UserFollowingFragment,
    data: { following },
  });
};

const UserFollowingCountFragment = gql(`
fragment UserFollowingCountFragment on User {
  followingCount
  followersCount
}
`);

const updateUserFollowingCount = (
  cache: ApolloCache<NormalizedCacheObject>,
  { id, meId, value }: { id: string; meId: string; value: number },
) => {
  cache.updateFragment(
    {
      id: cache.identify({ __typename: 'User', id }),
      fragment: UserFollowingCountFragment,
    },
    (data) => {
      if (!data) return data;

      return {
        ...data,
        followersCount: data.followersCount + value,
      };
    },
  );
  cache.updateFragment(
    {
      id: cache.identify({ __typename: 'User', id: meId }),
      fragment: UserFollowingCountFragment,
    },
    (data) => {
      if (!data) return data;

      return {
        ...data,
        followingCount: data.followingCount + value,
      };
    },
  );
};

export const useFollow = (userId: string) => {
  const me = useMe();

  const [followMutation, result] = useMutation(Follow, {
    update(cache, { data }) {
      if (data?.follow) {
        writeUserFollowingFragment(cache, userId, true);
        updateUserFollowingCount(cache, {
          id: userId,
          meId: me.id,
          value: 1,
        });
      }
    },
  });

  const follow = () => {
    return followMutation({
      variables: {
        userId,
      },
      optimisticResponse: {
        follow: true,
      },
    });
  };

  return [follow, result] as const;
};

export const useUnfollow = (userId: string) => {
  const me = useMe();

  const [unfollowMutation, result] = useMutation(Unfollow, {
    update(cache, { data }) {
      if (data?.unfollow) {
        writeUserFollowingFragment(cache, userId, false);
        updateUserFollowingCount(cache, {
          id: userId,
          meId: me.id,
          value: -1,
        });
        cache.modify({
          id: cache.identify({ __typename: 'User', id: userId }),
          fields: {
            subscribedToUserPosts() {
              return false;
            },
          },
        });
      }
    },
  });

  const unfollow = () => {
    return unfollowMutation({
      variables: {
        userId,
      },
      optimisticResponse: {
        unfollow: true,
      },
    });
  };

  return [unfollow, result] as const;
};
